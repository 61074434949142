.formContainer {
  margin: 40px auto;
  box-shadow: 2px 2px 3px 0 rgb(117 124 129 / 12%);
  max-width: 620px;
  border: 1px solid #c8cdd2;
  text-align: center;
  font-family: "Arimo", "Arial", "sans-serif";
  // font-family: "Open Sans", sans-serif;
  h1 {
    margin: 0;
  }
}

.formTab {
  width: 100%;
  cursor: pointer;
  background: #f9f9fa;
  border-bottom: 1px solid #c8cdd2;
  font-weight: 600;
  color: #657482;
  text-decoration: none;
  flex: auto;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  box-shadow: none;
}

.formTab + .formTab {
  border-left: 1px solid #c8cdd2;
}

.activeFormTab {
  cursor: default;
  background: #fff;
  font-weight: 700;
  color: #2e3d49;
  border-bottom: none;
}

.formTitle {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4rem;
  max-width: 50rem;
  font-weight: 300;
}

.formText {
  font-size: 1rem;
  line-height: 2rem;
  max-width: 40rem;
}

.formWrapper {
  background-color: #fff;
  padding: 40px 60px;
}
