html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  background-color: #f3f6f7 !important;
}

.scrollbar {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    height: 6px;
    width: 6px;
    background-color: #eaeaeb;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #184c51;
    height: 6px;
    width: 6px;
    border-radius: 10px;
  }
}

.no-select {
  user-select: none;
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 16px;
}

.recharts-brush-texts {
  font-size: 10px;
}

.canvasjs-chart-credit {
  display: none;
}

.table-filters-wrapper {
  top: 0;
  width: 100%;
  padding: 25px;
  z-index: 100;
  border-bottom: 4px solid #434a54;
  background-color: #fff;
}

.MuiTooltip {
  font-size: 20px;
}

// .pageBreak {
//   display: block !important;
//   page-break-after: always !important;
//   page-break-before: always !important;
// }

.offseTtableHeader {
  top: 0px !important;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  body {
    margin: 0.5cm;
  }

  // .pageBreak {
  //   display: block !important;
  //   // page-break-after: always !important;
  //   page-break-before: always !important;
  // }

  .offseTtableHeader {
    top: 0px !important;
  }
}

@page {
  size: auto;
}
