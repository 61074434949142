.loadingContainer {
  display: inline-block;

  .loader {
    animation: spin 2s linear infinite;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
